<template>
  <div class="notice">
    {{ warning }}
  </div>
</template>

<script>
  export default {
    props: {
      warning: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .notice {
    background: $col_delta-lightest;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
  }
</style>
