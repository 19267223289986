import { APIORDER_CHANNEL } from "@tucktrucks/platform-base-public";
import { formatChannel } from "@tucktrucks/platform-base-public";

// This is appaling, what does "don't care about" mean
export function formatChannels(channels) {
  // filter out channels we don't care about.
  return channels
    .filter(
      (channel) =>
        channel != APIORDER_CHANNEL.PHONE &&
        channel != APIORDER_CHANNEL.CLICK_COLLECT_ASAP
    )
    .map((channel) => formatChannel(channel.name));
}
